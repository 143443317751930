import { AccountManager } from "./account-manager";
import { DeleteAccount } from "./delete-account";
import Keycloak from "keycloak-js";

const w = window as any;

function deleteTokens() {
  sessionStorage.removeItem("account-access-token");
  sessionStorage.removeItem("account-refresh-token");
  sessionStorage.removeItem("account-time-skew");
}

function getClientId() {
  const app_container = new URLSearchParams(window.location.search).get(
    "app_container",
  );
  if (app_container) {
    return `penny${app_container}`;
  }
  const fromSessionStorage = sessionStorage.getItem("account-client-id");
  if (fromSessionStorage) {
    return fromSessionStorage;
  }
  return "account-console";
}

const clientId = getClientId();

const initialized = sessionStorage.getItem("account-initialized");
if (!initialized) {
  deleteTokens();
  sessionStorage.setItem("account-initialized", "true");
}

const keycloak = new Keycloak({
  url: w.authUrl,
  realm: w.realm,
  clientId,
});
new DeleteAccount(keycloak);
const accountManager = new AccountManager(keycloak);

function saveTokens() {
  sessionStorage.setItem("account-access-token", keycloak.token);
  sessionStorage.setItem("account-refresh-token", keycloak.refreshToken);
  sessionStorage.setItem("account-time-skew", keycloak.timeSkew.toString());
}

function logout() {
  deleteTokens();
  keycloak.logout();
}

w.logout = logout;
const token = sessionStorage.getItem("account-access-token");
const refreshToken = sessionStorage.getItem("account-refresh-token");
const timeSkew = Number(sessionStorage.getItem("account-time-skew"));
keycloak.onTokenExpired = () => keycloak.updateToken().then(() => saveTokens());
keycloak
  .init({
    onLoad: "login-required",
    pkceMethod: "S256",
    token,
    refreshToken,
    timeSkew,
    checkLoginIframe: false,
  })
  .then(() => {
    if (!keycloak.authenticated) {
      deleteTokens();
    } else {
      saveTokens();
      (document.getElementById("email") as HTMLInputElement).value =
        keycloak.tokenParsed.email;

      const event = new CustomEvent("authentication-success");
      document.dispatchEvent(event);
    }
  })
  .catch((e) => {
    console.log(e);
    alert("failed to initialize keycloak");
  })
  .then(() => accountManager.resetToSaved());
